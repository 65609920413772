<template>
    <v-dialog persistent v-model="show" :width="appDimensions.authenticationDialog.width">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary" outlined class="mx-2">
                <v-icon left>
                    mdi-plus
                </v-icon>
                Add Authentication
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="justify-center"> Add Authentication Service </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-5">
                <v-select :items="['Google', 'Microsoft']" outlined dense label="Select Authentication Service"
                    v-model="authenticate.authenticationTpye"></v-select>
                <v-text-field label="Add Domain" placeholder="Ex. arsconnect.com" v-model="authenticate.domain">
                </v-text-field>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn @click="show = false" outlined color="error">
                    <v-icon class="mx-2" left>mdi-cancel</v-icon>Cancel
                </v-btn>
                <v-btn color="primary" @click="addAuthentication()">
                    <v-icon class="mx-2" left>mdi-plus</v-icon> Add Authentication
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'addAuthentication',
    data() {
        return {
            show: false,
            authenticate: {
                configured: true,
                authenticationTpye: '',
                domain: ''
            }
        }
    },
    computed:{
        ...mapGetters(['appDimensions'])
    },
    methods: {
        addAuthentication() {
            this.$store.dispatch('addAuthenticationService', this.authenticate)
            this.show = false
        }
    }
}
</script>