<template>
    <v-container fluid>
        <v-card elevation="0" class="px-3">
            <v-card-title class="text-h4 grey--text text--darken-3">
                Configurations
            </v-card-title>
        </v-card>
        <v-row class="mt-5" no-gutters>
            <v-col>
                <v-card max-height="60">
                    <v-row class="fill-height">
                        <v-col cols="4" align-self="center">
                            <v-card-title class="justify-center pa-0"> Add Authentication Service </v-card-title>
                        </v-col>
                        <v-col cols="4" align-self="center">
                            Using this users can login directly usign Office ID </v-col>
                        <v-col cols="4" align-self="center">
                            <v-card-actions class="justify-center pa-0">
                                <add-authentication></add-authentication>
                            </v-card-actions>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import addAuthentication from '../../components/dialogBox/users/addAuthentication.vue'
export default {
    name: 'configurations',
    components: {
        addAuthentication
    }
}
</script>